import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetailsModalComponent } from '../details-modal/details-modal.component';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss'],
})
export class ShowcaseComponent implements OnInit {
  tabs = [
    {
      label: 'Persiana',
      items: [
        {
          title: 'Persiana Double Vision',
          name: 'Double Vision',
          image: 'double-vision.jpg',
          description: `
          A Persiana Double Vision, além de agregar beleza em  qualquer ambiente, permite visualização externa sem a necessidade de sua abertura total, controlando a entrada de luz conforme seu recolhimento.
          `,
        },
        {
          title: 'Persiana Horizontal de Alumínio',
          name: 'Horizontal de Alumínio',
          image: 'horizontal-aluminio.jpg',
          description: `
          A Pesiana Horizontal de Alumínio é um grande sucesso por levar modernidade e funcionalidade ao ambiente. Possibilita, de forma simples, o controle da luminosidade em lâminas de 25mm e 50mm. <br/><br/>
          Esse modelo permite grande variedade de acabamentos e texturas, do metálico ao perolado e escovado.
          `,
        },
        {
          title: 'Persiana Horizontal de Madeira',
          name: 'Horizontal de Madeira',
          image: 'horizontal-madeira.jpg',
          description: `
          A Persiana Horizontal de Madeira é um verdadeiro clássico que se faz presente em muitos ambientes.<br/><br/>
          Esse modelo oferece total controle de luz, além de ser um ótimo isolante térmico. Seu ambiente permanece arejado e com privacidade.
          `,
        },
        {
          title: 'Persiana Rolô',
          name: 'Rolô',
          image: 'rolo.jpg',
          description: `
          Com tecnologia que agrega qualidade, modernidade e praticidade, a Persiana Rolô leva um design inovador que moderniza o ambiente, além de contar com diversos tecidos que vão do blackout ao screen que é propício para o fechamento de sacadas.
          `,
        },
        {
          title: 'Persiana Rolô Personalizada',
          name: 'Rolô Personalizada',
          image: 'rolo-personalizada.jpg',
          description: `
          Com a Persiana Rolô Personalizada você terá 100% liberdade de criação do design, o famoso “do it yourself”.<br/>
          Converse com nossos especialistas para uma Persiana rolô personalizada com o seu toque de exclusividade e modernidade.
          `,
        },
        {
          title: 'Persiana Rolô para Sacadas',
          name: 'Rolô para Sacadas',
          image: 'rolo-sacada.jpg',
          description: `
          A Persiana Rolô para Sacadas que leva um design inovador e moderno, conta com o tecido "screen tela solar" que é propício para o fechamento de sacadas.
          `,
        },
        {
          title: 'Persiana Romana',
          name: 'Romana',
          image: 'romana.jpg',
          description: `
          A Persiana Romana traz muita riqueza em seu design, tornado seu espaço mais charmoso e sofisticado.<br/><br/>
          Suas varetas proporcionam um toque original e moderno com uma extensa linha. As persianas romanas possuem uma larga escala de efeitos de luminosidade, graças aos tecidos com efeitos de transparência, translúcidos e o opaco (blackout).
          `,
        },
        {
          title: 'Persiana Romana Newave',
          name: 'Romana Newave',
          image: 'romana-newave.png',
          description: `
          A Persiana Romana Newave inova com suas ondulações levando muita sofisticação e requinte ao ambiente.<br/><br/>
          Podendo ser confeccionada em diversos efeitos de luminosidade, graças aos tecidos com transparência, translúcidos e opacos (blackout), ela pode ser acionada  de forma manual ou motorizada.
          `,
        },
        {
          title: 'Persiana Silonew',
          name: 'Silonew',
          image: 'silonew.jpg',
          description: `
          A Persiana Silonew oferece elegância com total controle de luz. Lâminas com tratamento antiestático e moderno sistema de acionamento, garante a privacidade, eficiência e beleza para seu ambiente.
          `,
        },
        {
          title: 'Persiana Vertical',
          name: 'Vertical',
          image: 'vertical.jpg',
          description: `
          Modelo mais clássico de persianas, a Persiana Vertical se encaixa perfeitamente tanto em ambiente comercial, como residencial, trazendo um leque enorme de cores texturas para um espaço mais agradável e moderno, com toque minimalista.
          `,
        },
        {
          title: 'Persiana Vertical Personalizada',
          name: 'Vertical Personalizada',
          image: 'vertical-personalizada.jpg',
          description: `
          Mais um modelo clássico que se enquadra no “faça-você-mesmo”. A Persiana Vertical Personalizada permite estampar o seu gosto em cada lâmina.<br/><br/>
          Na KLF você terá especialistas que vão te guiar para a melhor escolha personalizada.
          `,
        },
      ],
    },
    {
      label: 'Cortina',
      items: [
        {
          title: 'Cortina Celular',
          name: 'Celular',
          image: 'celular.jpg',
          description: `
          A Cortina Celular traz leveza, delicadeza e principalmente funcionalidade, oferecendo conforto térmico e acústico ao ambiente, reduzindo e filtrando a entrada de raios UV e ruídos externos. <br/><br/>
          Feita com células em formato sextavado, possui opções com camadas simples, duplas e triplas de células. O grande diferencial é que, mesmo que elas sejam manipuladas ou torcidas, sempre retornam a forma original por conta da Tecnologia Anti-Age.
          `,
        },
        {
          title: 'Cortina Celular de Teto',
          name: 'Celular de Teto',
          image: 'celular-teto.jpg',
          description: `
          Muito utilizada em países da América do Norte e Europa, a Cortina Celular de Teto é a solução ideal para proteção solar em coberturas de vidro e clarabóias, com estrutura 100% em alumínio e acionamento através de bastão manual.<br/><br/>
          Além de modernizar e sofisticar o ambiente, proporciona controle de luminosidade, térmico e acústico.
          `,
        },
        {
          title: 'Cortina Convencional',
          name: 'Convencional',
          image: 'convencional.jpg',
          description: `
          Clássica, porém contemporânea, a Cortina Convencional surgiu no Oriente, ganhando destaque na Europa, na época do Renascimento pela realeza. Muito aclamada nos dias de hoje, conta com grande variedade de opções de cores e tecidos para todos os ambientes.
          `,
        },
        {
          title: 'Cortina Divnew',
          name: 'Divnew',
          image: 'divnew.jpg',
          description: `
          Ideal para divisão de ambientes, a Cortina Divnew, também conhecida como Cortina Divisória, atrai muito pela elegância, modernidade e arrojo.<br/><br/>
          Muito procurada para ambientes com conceito aberto por conta de sua beleza e funcionalidade.
          `,
        },
        {
          title: 'Cortina Double Vision',
          name: 'Double Vision',
          image: 'double-vision.jpg',
          description: `
          A Cortina Double Vision, além de agregar beleza em  qualquer ambiente, permite visualização externa sem a necessidade de sua abertura total, controlando a entrada de luz conforme seu recolhimento.
          `,
        },
        {
          title: 'Cortina Hospitalar',
          name: 'Hospitalar',
          image: 'hospitalar.jpg',
          description: `
          As Cortinas Hospitalares da KLF são desenvolvidas visando o conforto e a privacidade do paciente e do profissional da saúde.<br/><br/>
          Esta cortina é desenvolvida respeitando toda a segurança necessária exigida pela Anvisa: resistente à chamas, repelente à água e óleo e não prolifera bactérias.
          `,
        },
        {
          title: 'Cortina Painel',
          name: 'Painel',
          image: 'painel.jpg',
          description: `
          A Cortina Painel enriquece o ambiente e valoriza espaços com um charme só dela. Com seu trilho de deslize leve e suave para suas folhas e praticidade inconfundível, é indicada para acabamentos em grandes vãos.
          `,
        },
        {
          title: 'Cortina Plissada',
          name: 'Plissada',
          image: 'plissada.jpg',
          description: `
          As Cortina Plissada, além de charmosa, oferece um conforto térmico e acústico ao ambiente, reduzindo e filtrando a entrada de raios U.V.’s e ruídos externos.<br/><br/>
          Caixa superior e base inferior em aço esmaltado, com proteção anti-corrosiva, tendo a caixa superior 27 x 19mm e a base inferior, 25mm de largura. Tecidos exclusivos disponíveis em diversas cores e padrões, possuindo tratamento anti-estático e anti-poeira. 
          `,
        },
        {
          title: 'Cortina Rolô',
          name: 'Rolô',
          image: 'rolo.jpg',
          description: `
          Com tecnologia que agrega qualidade, modernidade e praticidade, a Cortina Rolô leva um design inovador que moderniza o ambiente, além de contar com diversos tecidos que vão do blackout ao screen que é propício para o fechamento de sacadas.
          `,
        },
        {
          title: 'Cortina Rolô Personalizada',
          name: 'Rolô Personalizada',
          image: 'rolo-personalizada.jpg',
          description: `
          Com a Cortina Rolô Personalizada você terá 100% liberdade de criação do design, o famoso “do it yourself”.<br/>
          Converse com nossos especialistas para uma cortina rolô personalizada com o seu toque de exclusividade e modernidade.
          `,
        },
        {
          title: 'Cortina Rolô para Sacadas',
          name: 'Rolô para Sacadas',
          image: 'rolo-sacada.jpg',
          description: `
          A Cortina Rolô para Sacadas que leva um design inovador e moderno, conta com o tecido "screen tela solar" que é propício para o fechamento de sacadas.
          `,
        },
        {
          title: 'Cortina Romana',
          name: 'Romana',
          image: 'romana.jpg',
          description: `
          A Cortina Romana traz muita riqueza em seu design, tornado seu espaço mais charmoso e sofisticado.<br/><br/>
          Suas varetas proporcionam um toque original e moderno com uma extensa linha. As cortinas romanas possuem uma larga escala de efeitos de luminosidade, graças aos tecidos com efeitos de transparência, translúcidos e o opaco (blackout).
          `,
        },
        {
          title: 'Cortina Romana Newave',
          name: 'Romana Newave',
          image: 'romana-newave.png',
          description: `
          A Cortina Romana Newave inova com suas ondulações levando muita sofisticação e requinte ao ambiente.<br/><br/>
          Podendo ser confeccionada em diversos efeitos de luminosidade, graças aos tecidos com transparência, translúcidos e opacos (blackout), ela pode ser acionada  de forma manual ou motorizada.
          `,
        },
      ],
    },
    {
      label: 'Papel de Parede',
      description: `Se você cansou de ter que pintar as paredes toda vez que resolve mudar o visual da sua casa, os papéis de parede podem ser uma solução prática e acessível. Cada vez mais em evidência, temos uma infinidade de texturas, cores e estampas pra você escolher. Por isso temos um time de especialistas pronto para ajudar nessa escolha. E não é somente a beleza do papel de parede que faz dele um item indispensável na decoração dos espaços. Ele também apresenta a função térmica, que faz com que o ambiente fique mais aconchegante.
        <br/><br/>
        Entre em contato com nossa equipe de consultores para o melhor custo-benefício, beleza e conforto.
        `,
    },
    {
      label: 'Limpeza e Manutenção',
      description: `A KLF tem um time especializado em manutenção de cortinas e persianas. Confira alguns serviços abaixo:<br/><br/>
        * Limpeza e manutenção de cortinas (consulte disponibilidade)<br/>
        * Limpeza de persiana vertical e horizontal;<br/>
        * Limpeza de lâminas de alumínio, PVC e tecido;
        <br/><br/>
        ** Durante a limpeza, caso deseje, a KLF faz a revisão geral da persiana e efetua a troca das peças desgastadas ou com defeitos, fazendo também a regulagem e lubrificação dos trilhos.`,
    },
  ];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openDetails(item) {
    let tracking = localStorage.getItem('tracking')
      ? JSON.parse(localStorage.getItem('tracking'))
      : { items: [] };
    if (tracking['items'].length > 0) tracking['items'].push(item.title);
    else {
      tracking['items'].push(item.title);
    }
    localStorage.setItem('tracking', '');
    localStorage.setItem('tracking', JSON.stringify(tracking));

    this.dialog.open(DetailsModalComponent, {
      panelClass: ['details-modal', 'custom-modal'],
      data: {
        title: item.title,
        description: item.description,
        image: item.image,
      },
    });
  }

  scrollTo(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
