<div class="container">
  <ul class="cards">
    <li class="card">
      <div class="card__side card__side--front card__side--front-1">
        <div class="card__title card__title--1">
          <h4 class="card__heading">Persiana</h4>
        </div>
      </div>
      <div class="card__side card__side--back card__side--back-1">
        <div class="card__details">
          <div class="card__details__icon card__details__icon--1"></div>
          <p class="card__details__text">
            As persianas deixam a decoração do ambiente mais moderna e protegem
            dos efeitos do sol. Temos todos os tipos: horizontais, painel, rolo,
            romana e celular.
          </p>
        </div>
      </div>
    </li>
    <li class="card">
      <div class="card__side card__side--front card__side--front-2">
        <div class="card__title card__title--1">
          <h4 class="card__heading">Cortina</h4>
        </div>
      </div>
      <div class="card__side card__side--back card__side--back-2">
        <div class="card__details">
          <div class="card__details__icon card__details__icon--2"></div>
          <p class="card__details__text">
            As persianas deixam a decoração do ambiente mais moderna e protegem
            dos efeitos do sol. Temos todos os tipos: horizontais, painel, rolo,
            romana e celular.
          </p>
        </div>
      </div>
    </li>
    <li class="card">
      <div class="card__side card__side--front card__side--front-3">
        <div class="card__title card__title--1">
          <h4 class="card__heading">Papel de Parede</h4>
        </div>
      </div>
      <div class="card__side card__side--back card__side--back-3">
        <div class="card__details">
          <div class="card__details__icon card__details__icon--3"></div>
          <p class="card__details__text">
            As persianas deixam a decoração do ambiente mais moderna e protegem
            dos efeitos do sol. Temos todos os tipos: horizontais, painel, rolo,
            romana e celular.
          </p>
        </div>
      </div>
    </li>
  </ul>
</div>
