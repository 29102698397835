<a class="btn-close" mat-dialog-close><mat-icon>close</mat-icon></a>
<p mat-dialog-title>Dúvidas Frequentes</p>
<div mat-dialog-content>
    <div class="tabs">
        <div class="tab">
            <input type="checkbox" id="rd1" name="rd1"/>
            <label class="tab-label" for="rd1">Qual a diferença entre Persiana e Cortina?</label>
            <div class="tab-content">
                A Diferença entre Cortinas e Persianas é que as Persianas Verticais (em material PVC, tecidos black-out, rústicos, translúcidos) e Horizontais (16, 25 e 50MM em alumínio, madeira e PVC),  BASCULAM, ou seja, suas lâminas proporcionam um giro de 180° graus no eixo, proporcionando um controle ou entrada de luz gradual estando elas ainda fechadas, não recolhidas.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd2" name="rd2"/>
            <label class="tab-label" for="rd2">Com que frequência deve ser realizada a limpeza das cortinas e persianas?</label>
            <div class="tab-content">
                Depende de cada ambiente. Em geral, recomenda-se realizar uma simples limpeza semanalmente. Uma limpeza completa pode ser necessária após 1 ou 2 anos de uso.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd3" name="rd3"/>
            <label class="tab-label" for="rd3">Todos os modelos podem ser lavados?</label>
            <div class="tab-content">
                Não. Em alguns modelos blackouts ou persianas de madeira, a limpeza é realizada a seco. Verifique disponibilidade com nossos especialistas.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd4" name="rd4"/>
            <label class="tab-label" for="rd4">Posso motorizar uma cortina ou persiana existente?</label>
            <div class="tab-content">
                Pode, sim! Existe uma grande tendência na automatização de persianas e cortinas. Portanto, entre em contato com nossos especialistas para solicitar um orçamento.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd5" name="rd5"/>
            <label class="tab-label" for="rd5">Como deve ser realizada a limpeza das cortinas e persianas?
            </label>
            <div class="tab-content">
                A simples limpeza pode ser realizada utilizando espanador ou aspirador com bocal de escova. Para a limpeza completa, entre em contato com nossos especialistas.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd6" name="rd6"/>
            <label class="tab-label" for="rd6">Quais cuidados devem ser tomados para aumentar a vida útil do produto?
            </label>
            <div class="tab-content">
                Deve-se realizar com frequência a limpeza dos produtos. Deixar o produto fora do alcance de crianças e animais domésticos. Evitar a exposição dos produtos a ambientes extremos, tais como umidade excessiva e maresia. Não deixar janelas abertas com a cortina ou persiana estendida. Ventos e chuvas podem danificar tecidos e lâminas.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd7" name="rd7"/>
            <label class="tab-label" for="rd7">Qual tecido escolher?
            </label>
            <div class="tab-content">
                Cada tecido possui características específicas, e a sua escolha depende do local onde o produto será instalado. Escolha um tecido que combine com a sua decoração. Nossos representantes possuem uma ampla gama de tecidos que compõem perfeitamente com qualquer ambiente de sua residência.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd8" name="rd8"/>
            <label class="tab-label" for="rd8">Por que escolher os produtos da KLF?
            </label>
            <div class="tab-content">
                A KLF tem um time de especialistas, sempre preocupada em atender e produzir produtos com qualidade superior. A empresa acompanha ativamente tendências locais e internacionais, buscando inovar e trazer aos seus clientes as principais novidades do mercado.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd9" name="rd9"/>
            <label class="tab-label" for="rd9">Como faço meu pedido?
            </label>
            <div class="tab-content">
                Entre em contato conosco preenchendo o formulário disponível em nossa página e solicite um orçamento. A nossa equipe comercial e técnica é qualificada para oferecer as melhores opções, medir corretamente e instalar suas cortinas e persianas.
            </div>
        </div>
        <div class="tab">
            <input type="checkbox" id="rd10" name="rd10"/>
            <label class="tab-label" for="rd10">Posso usar persianas e cortinas na mesma janela?
            </label>
            <div class="tab-content">
                Sim! É perfeitamente possível combinar persianas e cortinas em diversos cômodos. As persianas trazem privacidade e modernidade, enquanto as cortinas garantem isolamento térmico e suavidade.
A dica é usar cores que combinem entre si e não deixar o conjunto muito pesado. Muitas camadas devem ser evitadas quando se combinam cortinas com persianas.
<br/><br/>
            </div>
            <div class="tab">
                <input type="checkbox" id="rd11" name="rd11"/>
                <label class="tab-label" for="rd11">Encontro papéis de parede na KLF?
                </label>
                <div class="tab-content">
                    Sim! A KLF tem um vasto leque de modelos de papéis de parede. Você pode entrar em contato com nossos especialistas através do formulário na página inicial solicitando seu orçamento.
                </div>
            </div>
        </div>
    </div>
</div>