<header>
  <!-- <input #toggleCheck type="checkbox" id="menu" />
  <label for="menu" #toggle onclick></label> -->

  <a class="logo" [routerLink]="['/']" routerLinkActive="router-link-active">
    <img alt="Logo" src="../../../assets/images/klf-logo.jpg" />
  </a>

  <nav>
    <label #toggle for="drop" class="toggle" (click)="navMenu()"><mat-icon>menu</mat-icon></label>
    <input #toggleCheck type="checkbox" id="drop" />
    <ul class="menu">
      <li><a (click)="scroll('sobre')">Sobre</a></li>
      <li>
        <!-- First Tier Drop Down -->
        <label #persianaToggle for="drop-1" class="toggle">Persiana <mat-icon>keyboard_arrow_down</mat-icon></label>
        <a (click)="scrollTabs('persiana', '')">Persiana <mat-icon>keyboard_arrow_down</mat-icon></a>
        <input #persianaCheck type="checkbox" id="drop-1" />
        <ul class="submenu">
          <li><a (click)="scrollTabs('persiana', 'doubleVision')">Double Vision</a></li>
          <li><a (click)="scrollTabs('persiana', 'horizontalAluminio')">Horizontal de Alumínio</a></li>
          <li><a (click)="scrollTabs('persiana', 'horizontalMadeira')">Horizontal de Madeira</a></li>
          <li><a (click)="scrollTabs('persiana', 'rolo')">Rolô</a></li>
          <li><a (click)="scrollTabs('persiana', 'roloPersonalizada')">Rolô Personalizada</a></li>
          <li><a (click)="scrollTabs('persiana', 'roloSacada')">Rolô para Sacadas</a></li>
          <li><a (click)="scrollTabs('persiana', 'romana')">Romana</a></li>
          <li><a (click)="scrollTabs('persiana', 'romanaNewave')">Romana Newave</a></li>
          <li><a (click)="scrollTabs('persiana', 'silonew')">Silonew</a></li>
          <li><a (click)="scrollTabs('persiana', 'vertical')">Vertical</a></li>
          <li><a (click)="scrollTabs('persiana', 'verticalPersonalizada')">Vertical Personalizada</a></li>
        </ul>
      </li>
      <li>
        <label #cortinaToggle for="drop-2" class="toggle">Cortina <mat-icon>keyboard_arrow_down</mat-icon></label>
        <a (click)="scrollTabs('cortina', '')">Cortina <mat-icon>keyboard_arrow_down</mat-icon></a>
        <input #cortinaCheck type="checkbox" id="drop-2" />
        <ul class="submenu">
          <li><a (click)="scrollTabs('cortina', 'celular')">Celular</a></li>
          <li><a (click)="scrollTabs('cortina', 'celularTeto')">Celular de Teto</a></li>
          <li><a (click)="scrollTabs('cortina', 'convencional')">Convencional</a></li>
          <li><a (click)="scrollTabs('cortina', 'divnew')">Divnew</a></li>
          <li><a (click)="scrollTabs('cortina', 'doubleVision')">Double Vision</a></li>
          <li><a (click)="scrollTabs('cortina', 'hospitalar')">Hospitalar</a></li>
          <li><a (click)="scrollTabs('cortina', 'painel')">Painel</a></li>
          <li><a (click)="scrollTabs('cortina', 'plissada')">Plissada</a></li>
          <li><a (click)="scrollTabs('cortina', 'rolo')">Rolô</a></li>
          <li><a (click)="scrollTabs('cortina', 'roloPersonalizada')">Rolô Personalizada</a></li>
          <li><a (click)="scrollTabs('cortina', 'roloSacada')">Rolô para Sacadas</a></li>
          <li><a (click)="scrollTabs('cortina', 'romana')">Romana</a></li>
          <li><a (click)="scrollTabs('cortina', 'romanaNewave')">Romana Newave</a></li>
        </ul>
      </li>
      <li><a (click)="scrollTabs('papel', '')">Papel de Parede</a></li>
      <li><a (click)="scrollTabs('limpeza', '')">Limpeza e Manutenção</a></li>
      <li><a (click)="scroll('contato')">Contato</a></li>
      <li><a (click)="scroll('parceiros')">Parceiros</a></li>
    </ul>
  </nav>
</header>
