import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FaqModalComponent } from 'src/app/components/faq-modal/faq-modal.component';
import { SimpleModalComponent } from '../simple-modal/simple-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  modalContent = {
    'seguranca-privacidade': {
      title: 'Segurança e Privacidade',
      content: `
        <p class="m-v-20">
        Respeito, ética e integridade são os pilares em que a KLF Persianas sustenta o comprometimento com os clientes, oferecendo um serviço com total segurança.
        </p>
        <p class="m-v-20">
        Todas as informações passadas pelos clientes são de uso único e exclusivo da KLF Persianas, com a intenção de atender os clientes da melhor maneira possível, garantindo agilidade e usabilidade no atendimento, processos de entrega e informações ao cliente.
        </p>
        <p class="m-v-20">
        O site da KLF Persianas se encontra hospedado em servidores 100% seguros, com criptografia de dados que impossibilitam a interceptação e tornam a compra completamente segura. O site é certificado por empresas internacionais em segurança e trânsito de dados online.
        </p>
        <p class="m-v-20">
        A KLF Persianas compromete-se em não fornecer nenhum dado dos seus clientes como e-mail, endereço e outros dados pessoais à qualquer pessoa física ou jurídica.
        </p>
      `,
    },
    'servicos-projetos': {
      title: 'Serviços e Projetos',
      content: `
        <p class="m-v-20">
        A KLF Persianas pensando na comodidade de nossos clientes, oferece serviços de instalação para todos produtos comercializados em nosso site, com garantia de 1 ano. Para mais informações como prazo e valores, consulte-nos atráves do whatsapp (11) 99847-4104 .
        </p>
        <p class="m-v-20">
        Também realizamos projetos com medidas e modelos especificos de nossos produtos, entre em contato conosco atráves do whatsapp (11) 99847-4104 , e solicite um orçamento.
        </p>
      `,
    },
  };

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openFeedbackDialog(modal): void {
    this.dialog.open(SimpleModalComponent, {
      panelClass: [modal + '-dialog', 'custom-modal'],
      data: {
        title: this.modalContent[modal].title,
        text: this.modalContent[modal].content,
        btn: 'Fechar',
      },
    });
  }

  openFaqDialog(): void {
    this.dialog.open(FaqModalComponent, {
      panelClass: ['duvidas-frequentes-dialog', 'custom-modal']
    });
  }
}
