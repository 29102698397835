<a class="btn-close" mat-dialog-close><mat-icon>close</mat-icon></a>
<p mat-dialog-title>Não encontrou o que precisa?</p>
<div mat-dialog-content>
  <p>
    Temos especialistas prontos para te atender e prestar a consultoria
    adequada para a sua necessidade.
  </p>
  <p>
    Clicando no botão abaixo você será redirecionado para o nosso
    WhatsApp.
  </p>

  <div class="link-whats-container container">
    <a
      class="link-whats"
      title="Falar agora no WhatsApp"
      target="_blank"
      href="https://api.whatsapp.com/send?text=Olá! Vim pelo site e gostaria de um auxílio&phone=5511998474104"
      ><i class="whats-icon"></i><span>WhatsApp</span></a
    >
  </div>
</div>
