import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import AOS from 'aos';
import { ExitIntentModalComponent } from './components/exit-intent-modal/exit-intent-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'KLF Persianas';
  exit = false;

  // POPUP de intenção de saída do site
  @HostListener('document:mouseout', ['$event'])
  exitIntent(e) {
    e = e ? e : window.event;
    let from = e.relatedTarget || e.toElement;
    let top = e.clientY;

    if ((top < 10 && !from) || (from?.nodeName == 'HTML' && top < 10)) {
      if (!this.exit) {
        this.openExitIntentModal();
        this.exit = true;
      }
    }
  }

  constructor(private router: Router, private dialog: MatDialog) {
    this.router.events.subscribe(($event) => {
      if ($event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
      }
    });

    AOS.init();

    localStorage.setItem('tracking', '');
  }

  ngOnInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  openExitIntentModal() {
    this.dialog.open(ExitIntentModalComponent, {
      panelClass: ['exit-intent-modal', 'custom-modal'],
    });
  }
}
