<div class="swiper-container">
  <div class="swiper-wrapper">
    <div
      class="swiper-slide"
      *ngFor="let slide of slides"
      [style.backgroundImage]="
        'url(../../../../assets/galeria/' + slide.image + ')'
      "
    >
      <div class="swiper-slide__content">
        <h2 class="swiper-slide__title">{{ slide.title }}</h2>
        <p class="swiper-slide__description">{{ slide.description }}</p>
      </div>
    </div>
  </div>
</div>
