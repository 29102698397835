<div class="container">
  <h2>Entre em contato</h2>
  <p class="text">Temos especialistas prontos para te <strong>atender</strong><br/> e <strong>prestar a consultoria</strong> adequada. para a sua necessidade</p>

  <div class="row">
    <div
      class="ilustra"
    ></div>
    <form class="form" [formGroup]="contactForm">
      <div class="fields-content">
        <mat-form-field
          appearance="fill"
          [floatLabel]="'auto'"
          class="example-full-width"
          [ngClass]="{ disabled: loading }"
        >
          <mat-label>Nome</mat-label>
          <input
            matInput
            formControlName="name"
            placeholder="Digite seu nome"
          />
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          [floatLabel]="'auto'"
          class="example-full-width"
          [ngClass]="{ disabled: loading }"
        >
          <mat-label>Telefone</mat-label>
          <input
            matInput
            type="tel"
            formControlName="phone"
            placeholder="(00) 0000-0000"
            [mask]="maskPhone"
            (keyup)="changeMask()"
          />
        </mat-form-field>
      </div>

      <mat-form-field
        appearance="fill"
        [floatLabel]="'auto'"
        class="example-full-width"
        [ngClass]="{ disabled: loading }"
      >
        <mat-label>E-mail</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="seuemail@email.com"
        />
        <mat-error
          *ngIf="
            contactForm.get('email').hasError('required') &&
            contactForm.get('email').touched
          "
          >Campo obrigatório</mat-error
        >
        <mat-error
          *ngIf="
            (contactForm.get('email').hasError('email') ||
              contactForm.get('email').hasError('invalidEmail')) &&
            !contactForm.get('email').hasError('required') &&
            contactForm.get('email').touched
          "
          >E-mail inválido</mat-error
        >
      </mat-form-field>

      <!-- <mat-form-field
        appearance="fill"
        [floatLabel]="'auto'"
        class="example-full-width"
        [ngClass]="{ disabled: loading }"
      >
        <mat-select formControlName="about">
          <mat-option>Selecione uma opção</mat-option>
          <mat-option value="Persiana">Persiana</mat-option>
          <mat-option value="Cortina">Cortina</mat-option>
          <mat-option value="Papel de Parede">Papel de Parede</mat-option>
          <mat-option value="Consultoria">Consultoria</mat-option>
          <mat-option value="Outro">Outro</mat-option>
        </mat-select>
        <mat-label>Assunto</mat-label>
      </mat-form-field> -->

      <mat-form-field
        appearance="fill"
        class="example-full-width"
        [ngClass]="{ disabled: loading }"
      >
        <mat-label>Mensagem</mat-label>
        <textarea matInput formControlName="message"></textarea>
        <mat-hint>máximo 500 caracteres</mat-hint>
        <mat-error
          *ngIf="
            contactForm.get('message').hasError('required') &&
            contactForm.get('message').touched
          "
          >Campo obrigatório</mat-error
        >
        <mat-error
          *ngIf="
            contactForm.get('message').hasError('maxlength') &&
            !contactForm.get('message').hasError('required') &&
            contactForm.get('message').touched
          "
          >Insira no máximo 500 caracteres</mat-error
        >
      </mat-form-field>

      <app-button
        class="app-button"
        [text]="'Enviar'"
        [disabled]="loading"
        [status]="loading ? 'loading' : ''"
        (btnClick)="onSubmit()"
      ></app-button>
    </form>
  </div>

  <div class="contacts-content">
    <div class="contacts-box social">
      <p class="contacts-box__title">Acompanhe nossas redes sociais</p>
      <a class="contacts-box__social desktop" target="_blank" href="https://www.instagram.com/klfpersianas/">
        <div
          class="contacts-box__social__icon contacts-box__social__icon--insta"
        ></div>
        <p class="contacts-box__social__name">@klfpersianas</p>
      </a>
      <a class="contacts-box__social mobile" target="_blank" href="http://instagram.com/_u/klfpersianas/">
        <div
          class="contacts-box__social__icon contacts-box__social__icon--insta"
        ></div>
        <p class="contacts-box__social__name">@klfpersianas</p>
      </a>
      <a class="contacts-box__social desktop face" target="_blank" href="https://www.facebook.com/klfpersiana/">
        <div
          class="contacts-box__social__icon contacts-box__social__icon--face"
        ></div>
        <p class="contacts-box__social__name">@klfpersiana</p>
      </a>
      <a class="contacts-box__social mobile" target="_blank" href="fb://page/102691534856372">
        <div
          class="contacts-box__social__icon contacts-box__social__icon--face"
        ></div>
        <p class="contacts-box__social__name">@klfpersiana</p>
      </a>
    </div>

    <div class="contacts-box others">
      <p class="contacts-box__title">Outras formas de contato</p>
      <div class="contacts-box__others">
        <div
          class="contacts-box__others__icon contacts-box__others__icon--whats"
        ></div>
        <p class="contacts-box__others__name">
          <a
            class="link-whats"
            title="Contato pelo WhatsApp"
            target="_blank"
            href="https://api.whatsapp.com/send?text=Olá! Vim pelo site e gostaria de fazer um orçamento.&phone=5511998474104"
            >Clique aqui</a
          > e fale diretamente com um de nossos atendentes
        </p>
      </div>
      <div class="contacts-box__others">
        <mat-icon
          class="contacts-box__others__icon contacts-box__others__icon--tel"
        >call</mat-icon>
        <p class="contacts-box__others__name">(11) 99847-4104 ou (11) 2239-3816</p>
      </div>
      <div class="contacts-box__others">
        <mat-icon
          class="contacts-box__others__icon contacts-box__others__icon--email"
        >alternate_email</mat-icon>
        <p class="contacts-box__others__name">klfpersianas@gmail.com</p>
      </div>
    </div>
  </div>
</div>
