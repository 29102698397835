declare var require: any;

import { AbstractControl } from '@angular/forms';
export class Validations {
  static NameValidations(control: AbstractControl) {
    let arrName = control.value ? control.value.trim().split(' ') : '',
      validated = true;

    if (arrName.length < 2) return { validName: true };
    else {
      arrName.forEach((element) => {
        if (element.length < 2) return (validated = false);
      });
    }

    if (validated) return null;

    return { validName: true };
  }

  static isValidMailFormat(control: AbstractControl) {
    var EMAIL_REGEXP = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

    if (
      control.value != '' && !EMAIL_REGEXP.test(control.value)
    ) {
      return { invalidEmail: true };
    }

    return null;
  }

  static isValidPhoneFormat(control: AbstractControl) {
    var PHONE_REGEXP = /^[0-9]+$/;

    if (
      control.value != '' &&
      (control.value.length <= 9 || !PHONE_REGEXP.test(control.value))
    ) {
      return { invalidPhone: true };
    }

    return null;
  }

  static DateValidations(control: AbstractControl) {
    let day = null,
      month = null,
      year = null,
      currentYear = Number(new Date().getFullYear()),
      user_date = control.value ? control.value : '';

    // se tiver barra entre os numeros, da split
    if (user_date.indexOf('/') > -1) {
      let res = user_date.split('/');
      if (res.length > 1) {
        day = res[0];
        month = res[1];
        year = res[2];
      }
    } else if (user_date.indexOf('-') > -1) {
      let res = user_date.split('-');
      if (res.length > 1) {
        day = res[2];
        month = res[1];
        year = res[0];
      }
    }

    // se nao tiver barra, divide eles por substring
    else {
      if (user_date.length === 8) {
        day = user_date.substring(0, 2);
        month = user_date.substring(2, 4);
        year = user_date.substring(4, 8);
      } else {
        return { dateInvalid: true };
      }
    }

    // verifica se um dos dados é nulo
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return { dateInvalid: true };
    }

    day = Number(day);
    month = Number(month);
    year = Number(year);

    // checa o dia
    if (day < 1 || day > 31) {
      return { dateInvalid: true };
    }

    // checa o mes
    if (month < 1 || month > 12) {
      return { dateInvalid: true };
    }

    // abril, junho, setembro e novembro tem so 30 dias
    if (month === 4 || month === 6 || month === 9 || month === 11) {
      if (day === 31) {
        return { dateInvalid: true };
      }
    }

    // fevereiro so tem 29 dias em ano bissexto
    if (month == 2) {
      let bi_year = year % 4 === 0 && (year % 100 !== 0 || year % 400 == 0);
      if (day > 29 || (day === 29 && !bi_year)) {
        return { dateInvalid: true };
      }
    }

    // se a pessoa tiver menos de um ano e mais de 120
    if (year > currentYear || year <= currentYear - 120) {
      return { dateInvalid: true };
    }

    if (year === currentYear) {
      // new Date().getMonth() retorna numeros de 0 a 11
      let currentMonth = new Date().getMonth() + 1,
        currentDay = new Date().getDate();
      // se o mes for maior que o atual ou se o mes for igual e o dia ser maior
      if (month > currentMonth || (month == currentMonth && day > currentDay)) {
        return { dateInvalid: true };
      }
    }

    return null;
  }

  static formatDateNativeInput(date) {
    if (date.length === 10) {
      let arrayDate = date.split('-');
      return arrayDate[2] + arrayDate[1] + arrayDate[0];
    }
    return;
  }

  static calcIdade(data) {
    let d = new Date(),
      ano_atual = d.getFullYear(),
      mes_atual = d.getMonth() + 1,
      dia_atual = d.getDate();
    const hasNoHifen = data.indexOf('-') === -1;

    if (hasNoHifen) {
      data = data.replace(/\D/g, '');
      data = data.replace(/(\d{2})(\d)/, '$1-$2');
      data = data.replace(/(\d{2})(\d)/, '$1-$2');
      data = data.replace(/(\d{4})(\d)/, '$1-$2');
    }

    let split = data.split('-');

    if (hasNoHifen) {
      var data_americana = new Date(split[2], split[1] - 1, split[0]),
        vAno = data_americana.getFullYear(),
        vMes = data_americana.getMonth() + 1,
        vDia = data_americana.getDate();
    } else {
      var data_americana = new Date(split[0], split[1] - 1, split[2]),
        vAno = data_americana.getFullYear(),
        vMes = data_americana.getMonth() + 1,
        vDia = data_americana.getDate();
    }

    let ano_aniversario = +vAno,
      mes_aniversario = +vMes,
      dia_aniversario = +vDia,
      quantos_anos = ano_atual - ano_aniversario;

    if (hasNoHifen) {
      if (split[2] == '0000') return false;
    } else {
      if (split[0] == '0000') return false;
    }

    if (
      mes_atual < mes_aniversario ||
      (mes_atual == mes_aniversario && dia_atual < dia_aniversario)
    ) {
      quantos_anos--;
    }
    return quantos_anos < 0 ? 0 : quantos_anos;
  }

  static validateAge(value, expectedAge) {
    const age = this.calcIdade(value);
    return age >= expectedAge;
  }

  static validateDateField(control, expectedAge) {
    let user_age = control.value ? control.value : '';

    if (!Validations.validateAge(user_age, expectedAge)) {
      return { dateInvalid: true };
    }

    return null;
  }

  static typeKinship: string;

  static DateValidationMaiority(control: AbstractControl) {
    return Validations.validateDateField(control, 18);
  }

  static DateValidationMoreThanOneYear(control: AbstractControl) {
    return Validations.validateDateField(control, 1);
  }

  static validateMinLength(control: AbstractControl) {
    if (control.value && control.value.length > 9) {
      const phoneNumber = control.value.replace(/\D/g, '');

      // Se o telefone comecar com 9 mas nao tiver o nono digito
      if (phoneNumber.charAt(2) === '9' && phoneNumber.length === 10) {
        return { invalid_cellphone: true };
      }

      // Se nao comecar com 9 mas tiver o nono digito
      else if (phoneNumber.charAt(2) !== '9' && phoneNumber.length > 10) {
        return { minlength: true };
      }
    }
  }
}
