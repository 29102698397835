<button
  [type]="type"
  class="btn"
  [ngClass]="{
    outline: style === 'outline',
    loading: status === 'loading'
  }"
  (click)="onBtnClick()"
  [disabled]="disabled"
>
  {{ text }}
</button>

<!-- 
  <app-button [type]="'submit'" class="app-button" [text]="'Pagar'" [disabled]="transactionForm.invalid || loading"
    [status]="loading ? 'loading' : ''" (btnClick)="onSubmit()"></app-button>
 -->
