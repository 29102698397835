<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="footer__opening">
        <p><strong>Horários de Funcionamento</strong></p>
        <p>Segunda a sexta - 9h às 17h</p>
        <p>Sábado - 09h às 14h</p>
        <p>Domingo - Fechado</p>
      </div>
      <div class="footer__payments">
        <p><strong>Formas de Pagamento</strong></p>
        <div class="payments">
          <div class="payments__type payments__type--cash"></div>
          <div class="payments__type payments__type--master"></div>
          <div class="payments__type payments__type--visa"></div>
          <div class="payments__type payments__type--elo"></div>
          <div class="payments__type payments__type--amex"></div>
          <div class="payments__type payments__type--hipercard"></div>
        </div>
      </div>
      <div class="footer__links">
        <p><strong>Mais informações</strong></p>
        <a class="footer__links__link" (click)="openFaqDialog()">Dúvidas Frequentes</a>
        <a class="footer__links__link" (click)="openFeedbackDialog('seguranca-privacidade')">Segurança e Privacidade</a>
        <a class="footer__links__link" (click)="openFeedbackDialog('servicos-projetos')">Serviços e Projetos</a>
      </div>
    </div>
    <div class="footer__copyright">
      <p>
        Copyright KLF Persianas - Todos os direitos reservados
      </p>
      <div class="row-snaps">
        <strong>Criado por</strong>
        <a
          href="https://www.asnaps.com.br"
          target="_blank"
          class="footer__copyright__snaps"
        ></a>
      </div>
    </div>
  </div>
</footer>
