<div class="application">
  <app-header></app-header>
  <main class="application__content">
    <router-outlet></router-outlet>
    <div class="link-whats-container container">
      <a
        class="link-whats"
        title="Falar agora no WhatsApp"
        target="_blank"
        href="https://api.whatsapp.com/send?text=Olá! Vim pelo site e gostaria de fazer um orçamento.&phone=5511998474104"
      ></a>
    </div>
  </main>
  <app-footer></app-footer>
</div>
