import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Validations } from 'src/app/helpers/validators/validations';
import { SimpleModalComponent } from 'src/app/shared/base-components/simple-modal/simple-modal.component';
import { ContactService } from 'src/app/services/contact/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm;
  loading = false;
  maskPhone = '(00) 0000-00009';

  constructor(private _formBuilder: FormBuilder, private dialog: MatDialog, private _contactService: ContactService) {
    this.contactForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email, Validations.isValidMailFormat]],
      // about: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.maxLength(500)]]
    });
  }

  ngOnInit(): void {}

  openDialog() {
    this.dialog.open(SimpleModalComponent, {
      panelClass: ['success-dialog', 'simple-modal', 'custom-modal'],
      data: {
        title: '',
        text: 'Seu contato foi enviado com sucesso!',
        btn: 'Fechar',},
    });
  }

  changeMask() {
    this.maskPhone = this.contactForm.controls['phone'].value.length > 10 ? '(00) 00000-0000' : '(00) 0000-00009';
  }

  async onSubmit() {
    this.markFormGroupTouched(this.contactForm);
    if (this.contactForm.invalid) return;
    this.loading = true;

    let tracking = localStorage.getItem('tracking') ? JSON.parse(localStorage.getItem('tracking')) : null;

    let contactData = {
      email: this.contactForm.get('email').value,
      name: this.contactForm.get('name').value,
      phone: this.contactForm.get('phone').value,
      // type: this.contactForm.get('about').value,
      message: this.contactForm.get('message').value,
      items: tracking ? tracking.items : []
    };

    this._contactService.sendContact(contactData).subscribe((contact) => {
      this.openDialog();
      this.loading = false;
      this.clearForm();
    });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  clearForm(): void {
    this.contactForm.reset();
    this.contactForm.markAsUntouched();
    this.contactForm.markAsPristine();
    Object.keys(this.contactForm.controls).forEach((name) => {
      let control = this.contactForm.controls[name];
      control.setErrors({ invalid: null });
      control.setValue('');
    });
  }

}
