import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
// Import any polyfill to enable smoothscroll for JS APIs
import smoothscrollPolyfill from 'smoothscroll-polyfill';
import { DetailsModalComponent } from 'src/app/components/details-modal/details-modal.component';
import { VerifyService } from 'src/app/helpers/verify/verify.service';

// (Unlike this package, smoothscroll-polyfill needs to be actively invoked: )
smoothscrollPolyfill.polyfill();

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  logged: boolean;
  name = '';
  isHome: boolean;
  @ViewChild('toggle', { static: false }) toggle: any;
  @ViewChild('toggleCheck', { static: false }) toggleCheck: any;
  @ViewChild('cortinaToggle', { static: false }) cortinaToggle: any;
  @ViewChild('cortinaCheck', { static: false }) cortinaCheck: any;
  @ViewChild('persianaToggle', { static: false }) persianaToggle: any;
  @ViewChild('persianaCheck', { static: false }) persianaCheck: any;

  tabs = {
    persiana: {
      doubleVision: {
        title: 'Persiana Double Vision',
        name: 'Double Vision',
        image: 'double-vision.jpg',
        description: `
          A Persiana Double Vision, além de agregar beleza em  qualquer ambiente, permite visualização externa sem a necessidade de sua abertura total, controlando a entrada de luz conforme seu recolhimento.
          `,
      },
      horizontalAluminio: {
        title: 'Persiana Horizontal de Alumínio',
        name: 'Horizontal de Alumínio',
        image: 'horizontal-aluminio.jpg',
        description: `
          A Pesiana Horizontal de Alumínio é um grande sucesso por levar modernidade e funcionalidade ao ambiente. Possibilita, de forma simples, o controle da luminosidade em lâminas de 25mm e 50mm. <br/><br/>
          Esse modelo permite grande variedade de acabamentos e texturas, do metálico ao perolado e escovado.
          `,
      },
      horizontalMadeira: {
        title: 'Persiana Horizontal de Madeira',
        name: 'Horizontal de Madeira',
        image: 'horizontal-madeira.jpg',
        description: `
          A Persiana Horizontal de Madeira é um verdadeiro clássico que se faz presente em muitos ambientes.<br/><br/>
          Esse modelo oferece total controle de luz, além de ser um ótimo isolante térmico. Seu ambiente permanece arejado e com privacidade.
          `,
      },
      rolo: {
        title: 'Persiana Rolô',
        name: 'Rolô',
        image: 'rolo.jpg',
        description: `
          Com tecnologia que agrega qualidade, modernidade e praticidade, a Persiana Rolô leva um design inovador que moderniza o ambiente, além de contar com diversos tecidos que vão do blackout ao screen que é propício para o fechamento de sacadas.
          `,
      },
      roloPersonalizada: {
        title: 'Persiana Rolô Personalizada',
        name: 'Rolô Personalizada',
        image: 'rolo-personalizada.jpg',
        description: `
          Com a Persiana Rolô Personalizada você terá 100% liberdade de criação do design, o famoso “do it yourself”.<br/>
          Converse com nossos especialistas para uma Persiana rolô personalizada com o seu toque de exclusividade e modernidade.
          `,
      },
      roloSacada: {
        title: 'Persiana Rolô para Sacadas',
        name: 'Rolô para Sacadas',
        image: 'rolo-sacada.jpg',
        description: `
        A Persiana Rolô para Sacadas que leva um design inovador e moderno, conta com o tecido "screen tela solar" que é propício para o fechamento de sacadas.
        `,
      },
      romana: {
        title: 'Persiana Romana',
        name: 'Romana',
        image: 'romana.jpg',
        description: `
          A Persiana Romana traz muita riqueza em seu design, tornado seu espaço mais charmoso e sofisticado.<br/><br/>
          Suas varetas proporcionam um toque original e moderno com uma extensa linha. As persianas romanas possuem uma larga escala de efeitos de luminosidade, graças aos tecidos com efeitos de transparência, translúcidos e o opaco (blackout).
          `,
      },
      romanaNewave: {
        title: 'Persiana Romana Newave',
        name: 'Romana Newave',
        image: 'romana-newave.png',
        description: `
          A Persiana Romana Newave inova com suas ondulações levando muita sofisticação e requinte ao ambiente.<br/><br/>
          Podendo ser confeccionada em diversos efeitos de luminosidade, graças aos tecidos com transparência, translúcidos e opacos (blackout), ela pode ser acionada  de forma manual ou motorizada.
          `,
      },
      silonew: {
        title: 'Persiana Silonew',
        name: 'Silonew',
        image: 'silonew.jpg',
        description: `
          A Persiana Silonew oferece elegância com total controle de luz. Lâminas com tratamento antiestático e moderno sistema de acionamento, garante a privacidade, eficiência e beleza para seu ambiente.
          `,
      },
      vertical: {
        title: 'Persiana Vertical',
        name: 'Vertical',
        image: 'vertical.jpg',
        description: `
          Modelo mais clássico de persianas, a Persiana Vertical se encaixa perfeitamente tanto em ambiente comercial, como residencial, trazendo um leque enorme de cores texturas para um espaço mais agradável e moderno, com toque minimalista.
          `,
      },
      verticalPersonalizada: {
        title: 'Persiana Vertical Personalizada',
        name: 'Vertical Personalizada',
        image: 'vertical-personalizada.jpg',
        description: `
          Mais um modelo clássico que se enquadra no “faça-você-mesmo”. A Persiana Vertical Personalizada permite estampar o seu gosto em cada lâmina.<br/><br/>
          Na KLF você terá especialistas que vão te guiar para a melhor escolha personalizada.
          `,
      },
    },
    cortina: {
      celular: {
        title: 'Cortina Celular',
        name: 'Celular',
        image: 'celular.jpg',
        description: `
          A Cortina Celular traz leveza, delicadeza e principalmente funcionalidade, oferecendo conforto térmico e acústico ao ambiente, reduzindo e filtrando a entrada de raios UV e ruídos externos. <br/><br/>
          Feita com células em formato sextavado, possui opções com camadas simples, duplas e triplas de células. O grande diferencial é que, mesmo que elas sejam manipuladas ou torcidas, sempre retornam a forma original por conta da Tecnologia Anti-Age.
          `,
      },
      celularTeto: {
        title: 'Cortina Celular de Teto',
        name: 'Celular de Teto',
        image: 'celular-teto.jpg',
        description: `
          Muito utilizada em países da América do Norte e Europa, a Cortina Celular de Teto é a solução ideal para proteção solar em coberturas de vidro e clarabóias, com estrutura 100% em alumínio e acionamento através de bastão manual.<br/><br/>
          Além de modernizar e sofisticar o ambiente, proporciona controle de luminosidade, térmico e acústico.
          `,
      },
      convencional: {
        title: 'Cortina Convencional',
        name: 'Convencional',
        image: 'convencional.jpg',
        description: `
        Clássica, porém contemporânea, a Cortina Convencional surgiu no Oriente, ganhando destaque na Europa, na época do Renascimento pela realeza. Muito aclamada nos dias de hoje, conta com grande variedade de opções de cores e tecidos para todos os ambientes.
        `,
      },
      divnew: {
        title: 'Cortina Divnew',
        name: 'Divnew',
        image: 'divnew.jpg',
        description: `
          Ideal para divisão de ambientes, a Cortina Divnew, também conhecida como Cortina Divisória, atrai muito pela elegância, modernidade e arrojo.<br/><br/>
          Muito procurada para ambientes com conceito aberto por conta de sua beleza e funcionalidade.
          `,
      },
      doubleVision: {
        title: 'Cortina Double Vision',
        name: 'Double Vision',
        image: 'double-vision.jpg',
        description: `
          A Cortina Double Vision, além de agregar beleza em  qualquer ambiente, permite visualização externa sem a necessidade de sua abertura total, controlando a entrada de luz conforme seu recolhimento.
          `,
      },
      hospitalar: {
        title: 'Cortina Hospitalar',
        name: 'Hospitalar',
        image: 'hospitalar.jpg',
        description: `
          As Cortinas Hospitalares da KLF são desenvolvidas visando o conforto e a privacidade do paciente e do profissional da saúde.<br/><br/>
          Esta cortina é desenvolvida respeitando toda a segurança necessária exigida pela Anvisa: resistente à chamas, repelente à água e óleo e não prolifera bactérias.
          `,
      },
      painel: {
        title: 'Cortina Painel',
        name: 'Painel',
        image: 'painel.jpg',
        description: `
          A Cortina Painel enriquece o ambiente e valoriza espaços com um charme só dela. Com seu trilho de deslize leve e suave para suas folhas e praticidade inconfundível, é indicada para acabamentos em grandes vãos.
          `,
      },
      plissada: {
        title: 'Cortina Plissada',
        name: 'Plissada',
        image: 'plissada.jpg',
        description: `
          As Cortina Plissada, além de charmosa, oferece um conforto térmico e acústico ao ambiente, reduzindo e filtrando a entrada de raios U.V.’s e ruídos externos.<br/><br/>
          Caixa superior e base inferior em aço esmaltado, com proteção anti-corrosiva, tendo a caixa superior 27 x 19mm e a base inferior, 25mm de largura. Tecidos exclusivos disponíveis em diversas cores e padrões, possuindo tratamento anti-estático e anti-poeira. 
          `,
      },
      rolo: {
        title: 'Cortina Rolô',
        name: 'Rolô',
        image: 'rolo.jpg',
        description: `
          Com tecnologia que agrega qualidade, modernidade e praticidade, a Cortina Rolô leva um design inovador que moderniza o ambiente, além de contar com diversos tecidos que vão do blackout ao screen que é propício para o fechamento de sacadas.
          `,
      },
      roloPersonalizada: {
        title: 'Cortina Rolô Personalizada',
        name: 'Rolô Personalizada',
        image: 'rolo-personalizada.jpg',
        description: `
          Com a Cortina Rolô Personalizada você terá 100% liberdade de criação do design, o famoso “do it yourself”.<br/>
          Converse com nossos especialistas para uma cortina rolô personalizada com o seu toque de exclusividade e modernidade.
          `,
      },
      roloSacada: {
        title: 'Cortina Rolô para Sacadas',
        name: 'Rolô para Sacadas',
        image: 'rolo-sacada.jpg',
        description: `
        A Cortina Rolô para Sacadas que leva um design inovador e moderno, conta com o tecido "screen tela solar" que é propício para o fechamento de sacadas.
        `,
      },
      romana: {
        title: 'Cortina Romana',
        name: 'Romana',
        image: 'romana.jpg',
        description: `
          A Cortina Romana traz muita riqueza em seu design, tornado seu espaço mais charmoso e sofisticado.<br/><br/>
          Suas varetas proporcionam um toque original e moderno com uma extensa linha. As cortinas romanas possuem uma larga escala de efeitos de luminosidade, graças aos tecidos com efeitos de transparência, translúcidos e o opaco (blackout).
          `,
      },
      romanaNewave: {
        title: 'Cortina Romana Newave',
        name: 'Romana Newave',
        image: 'romana-newave.png',
        description: `
          A Cortina Romana Newave inova com suas ondulações levando muita sofisticação e requinte ao ambiente.<br/><br/>
          Podendo ser confeccionada em diversos efeitos de luminosidade, graças aos tecidos com transparência, translúcidos e opacos (blackout), ela pode ser acionada  de forma manual ou motorizada.
          `,
      },
    },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private verify: VerifyService
  ) {
    this.router.events.subscribe(($event) => {
      if ($event instanceof NavigationEnd) {
        this.isHome = $event.url === '' ? true : false;
      }
    });
  }

  navMenu() {
    if (this.toggleCheck.nativeElement.checked) {
      if (this.cortinaCheck.nativeElement.checked)
        this.cortinaToggle.nativeElement.click();
      if (this.persianaCheck.nativeElement.checked)
        this.persianaToggle.nativeElement.click();
    }
  }

  ngOnInit() {}

  scroll(element: any) {
    this.toggle.nativeElement.click();

    let el = document.getElementById(element);
    if (this.verify.responsiveIsMobile.getValue()) {
      let topOfElement = el.offsetTop - 100;
      window.scroll({ top: topOfElement, behavior: 'smooth' });
    } else {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollTabs(product, type?) {
    this.toggle.nativeElement.click();

    let el = document.getElementById('produtos');
    if (this.verify.responsiveIsMobile.getValue()) {
      let topOfElement = el.offsetTop - 100;
      window.scroll({ top: topOfElement, behavior: 'smooth' });
    } else {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    setTimeout(() => {
      switch (product) {
        case 'persiana':
          document.getElementById('mat-tab-label-0-0').click();
          document.getElementById('mat-tab-label-0-0').blur();
          break;
        case 'cortina':
          document.getElementById('mat-tab-label-0-1').click();
          document.getElementById('mat-tab-label-0-1').blur();
          break;
        case 'papel':
          document.getElementById('mat-tab-label-0-2').click();
          document.getElementById('mat-tab-label-0-2').blur();
          break;
        case 'limpeza':
          document.getElementById('mat-tab-label-0-3').click();
          document.getElementById('mat-tab-label-0-3').blur();
          break;
      }

      if (type) {
        this.dialog.open(DetailsModalComponent, {
          panelClass: ['details-modal', 'custom-modal'],
          data: {
            title: this.tabs[product][type].title,
            description: this.tabs[product][type].description,
            image: this.tabs[product][type].image,
          },
        });
      }
    }, 400);
  }
}
