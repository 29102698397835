import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PartnersModalComponent } from '../partners-modal/partners-modal.component';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openModal() {
    this.dialog.open(PartnersModalComponent, {
      panelClass: ['partners-dialog', 'custom-modal']
    });
  }
}
