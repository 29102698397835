import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
declare var Swiper: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, AfterViewInit {

  swiper;
  slides = [
    {
      title: 'Persiana',
      description: 'Estilo e conforto para o seu ambiente',
      image: '../../../assets/images/galeria/persiana.jpg'
    },
    {
      title: 'Cortina',
      description: 'Grande variedade de modelos para seu ambiente',
      image: '../../../assets/images/galeria/cortina2.jpg'
    },
    {
      title: 'Papel de Parede',
      description: 'Diversas texturas e estampas',
      image: '../../../assets/images/galeria/papel1.jpg'
    },
    {
      title: 'Limpeza e Manutenção',
      description: 'Sua decoração e bem-estar em dia',
      image: '../../../assets/images/galeria/limpeza.jpg'
    }
  ]

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.swiper = new Swiper(
      this.elementRef.nativeElement.querySelector('.swiper-container'),
      {
        speed: 1000,
        loop: true,
        autoplay: {
          delay: 5000,
        },
      }
    );

    this.swiper.autoplay.start();
  }
}
