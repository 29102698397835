<div
  class="modal-bg"
  [style.backgroundImage]="
    'url(../../../../../assets/images/produtos/' + data.image
  "
>
  <a class="btn-close" mat-dialog-close><mat-icon>close</mat-icon></a>
  <p mat-dialog-title>{{ data.title }}</p>
  <div class="row-content">
    <div class="content">
      <div
        class="content__img"
        [style.backgroundImage]="
          'url(../../../../../assets/images/produtos/' + data.image
        "
      ></div>
      <div
        mat-dialog-content
        [innerHTML]="data.description"
        class="content__text"
      ></div>
    </div>
    <div mat-dialog-actions>
      <app-button
        class="app-button"
        [text]="'Mandar Mensagem'"
        (click)="closeModal()"
      ></app-button>
      <a
        class="link-whats"
        title="Contato pelo WhatsApp"
        target="_blank"
        [href]="
          'https://api.whatsapp.com/send?text=Olá! Vim pelo site e gostaria de fazer um orçamento de ' +
          data.title +
          '.&phone=5511998474104'
        "
        ><i class="whats-icon"></i><span>WhatsApp</span></a
      >
    </div>
  </div>
</div>
