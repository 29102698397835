<a class="btn-close" mat-dialog-close><mat-icon>close</mat-icon></a>
<ng-container *ngIf="!success">
  <p mat-dialog-title>Quero ser parceiro</p>
  <div mat-dialog-content>
    <p>
      Preencha os campos com os seus dados que em breve entraremos em contato.
    </p>

    <form class="form" [formGroup]="contactForm">
      <div class="fields-content">
        <mat-form-field
          appearance="fill"
          [floatLabel]="'auto'"
          class="example-full-width"
          [ngClass]="{ disabled: loading }"
        >
          <mat-label>Nome</mat-label>
          <input
            matInput
            formControlName="name"
            placeholder="Digite seu nome"
          />
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          [floatLabel]="'auto'"
          class="example-full-width"
          [ngClass]="{ disabled: loading }"
        >
          <mat-label>Telefone</mat-label>
          <input
            matInput
            type="tel"
            formControlName="phone"
            placeholder="(00) 0000-0000"
            [mask]="maskPhone"
            (keyup)="changeMask()"
          />
        </mat-form-field>
      </div>

      <mat-form-field
        appearance="fill"
        [floatLabel]="'auto'"
        class="example-full-width"
        [ngClass]="{ disabled: loading }"
      >
        <mat-label>E-mail</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="seuemail@email.com"
        />
        <mat-error
          *ngIf="
            contactForm.get('email').hasError('required') &&
            contactForm.get('email').touched
          "
          >Campo obrigatório</mat-error
        >
        <mat-error
          *ngIf="
            (contactForm.get('email').hasError('email') ||
              contactForm.get('email').hasError('invalidEmail')) &&
            !contactForm.get('email').hasError('required') &&
            contactForm.get('email').touched
          "
          >E-mail inválido</mat-error
        >
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        [floatLabel]="'auto'"
        class="example-full-width"
        [ngClass]="{ disabled: loading }"
      >
        <mat-label>Profissão</mat-label>
        <input
          matInput
          formControlName="profession"
          placeholder="Digite sua profissão"
        />
      </mat-form-field>

      <app-button
        class="app-button"
        [text]="'Enviar'"
        [disabled]="loading"
        [status]="loading ? 'loading' : ''"
        (btnClick)="onSubmit()"
      ></app-button>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="success">
  <div class="msg-success">
    <p>
      Seu contato foi enviado com sucesso!
    </p>
  </div>
</ng-container>
