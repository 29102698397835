<div class="container showcase">
  <mat-tab-group dynamicHeight mat-align-tabs="center">
    <mat-tab class="tab" [label]="tab.label" *ngFor="let tab of tabs">
      <ng-template matTabContent>
        <div
          class="tab__description"
          *ngIf="tab.description"
          [innerHTML]="tab.description"
        ></div>

        <div class="actions" *ngIf="!tab.items">
          <app-button
            class="app-button"
            [text]="'Mandar Mensagem'"
            (click)="scrollTo('contato')"
          ></app-button>
          <a
            class="link-whats"
            title="Contato pelo WhatsApp"
            target="_blank"
            [href]="
              'https://api.whatsapp.com/send?text=Olá! Vim pelo site e gostaria de fazer um orçamento de ' +
              tab.label +
              '.&phone=5511998474104'
            "
            ><i class="whats-icon"></i><span>WhatsApp</span></a
          >
        </div>

        <ul class="tab__items" *ngIf="tab.items">
          <li
            class="tab__items__item item content"
            *ngFor="let item of tab.items"
            data-aos="zoom-in"
            data-aos-offset="50"
            data-aos-duration="500"
            data-aos-anchor-placement="top-bottom"
            (click)="openDetails(item)"
          >
            <div class="content-overlay"></div>
            <div
              class="item__img"
              [style.backgroundImage]="
                'url(../../../../../assets/images/produtos/' + item.image
              "
            ></div>
            <div class="content-details fadeIn-bottom">
              <h3 class="content-title">Detalhes</h3>
            </div>
            <p class="item__title">{{ item.name }}</p>
          </li>
        </ul>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
