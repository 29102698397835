<section class="galeria" id="galeria">
  <app-gallery></app-gallery>
</section>
<section class="sobre" id="sobre">
  <div class="container">
    <p class="text">
      Somos uma empresa paulistana prestadora de serviços, apaixonada por design de interiores, desde consultoria e pré-venda, até a venda e instalação de persianas, cortinas e papéis de parede.
    </p>
    <p class="text">
      Com profissionais especializados e com experiência de mais de 9 anos no mercado, garantimos o melhor atendimento no setor.
    </p>
  </div>
</section>
<section class="produtos" id="produtos">
  <app-showcase></app-showcase>
</section>
<section class="contato" id="contato">
  <app-contact></app-contact>
</section>
<section class="parceiros" id="parceiros">
  <app-partners></app-partners>
</section>