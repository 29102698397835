import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponentsModule } from '../shared/base-components/base-components.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { GalleryComponent } from './gallery/gallery.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ShowcaseComponent } from './showcase/showcase.component';
import { DetailsModalComponent } from './details-modal/details-modal.component';
import { ExitIntentModalComponent } from './exit-intent-modal/exit-intent-modal.component';
import { FaqModalComponent } from './faq-modal/faq-modal.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnersModalComponent } from './partners-modal/partners-modal.component';

@NgModule({
  declarations: [
    GalleryComponent,
    ServicesComponent,
    ContactComponent,
    ShowcaseComponent,
    DetailsModalComponent,
    ExitIntentModalComponent,
    FaqModalComponent,
    PartnersComponent,
    PartnersModalComponent,
  ],
  imports: [
    CommonModule,
    BaseComponentsModule,
    RouterModule,
    MaterialModule,
    // NguCarouselModule,
    MatFormFieldModule,
    NgxPageScrollModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxGalleryModule,
  ],
  exports: [
    GalleryComponent,
    ServicesComponent,
    ContactComponent,
    ShowcaseComponent,
    DetailsModalComponent,
    ExitIntentModalComponent,
    PartnersComponent,
    PartnersModalComponent
  ],
  providers: [
    HttpClientModule,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
})
export class ComponentsModule {}
