<div class="partners">
  <div class="container">
    <p class="partners__title">Quer ser nosso parceiro?</p>
    <app-button
      class="app-button"
      [text]="'Sim!'"
      [style]="'outline'"
      (btnClick)="openModal()"
    ></app-button>
  </div>
</div>
