import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exit-intent-modal',
  templateUrl: './exit-intent-modal.component.html',
  styleUrls: ['./exit-intent-modal.component.scss']
})
export class ExitIntentModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
